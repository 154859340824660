import React from 'react'
import MySite from './containers/MySite'


export default function App() {
  return (
    <div>
      <MySite />
    </div>
  )
}
